<template>
    <div>
        <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
            spinner="bar-fade-scale" />
        <v-snackbar v-model="showsnackbar" color="black" right>
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showsnackbar = false">
                        <v-icon style="color: white">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap>
            <v-flex xs12>
                <v-card-title class="elevation-0">
                    <span class="itemHeading">Festival Leaves</span>
                    <v-spacer></v-spacer>
                    <!-- <v-flex xs6 sm6 md3 pt-5 align-self-center>
              <v-text-field
                label="Search Employee"
                outlined
                dense
                class="rounded-xl"
                v-model="searchKeyword"
              ></v-text-field>
            </v-flex> -->
                    <v-flex xs6 sm6 md3 pt-5 align-self-center text-right>
                        <v-btn color="#005f32" rounded outlined class="mb-4" @click="addFestivalLeave = true">
                            <span style="font-family: kumbhRegular;">Add Festival</span>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <v-layout wrap justify-start>
                            <v-flex xs12 sm6 md4 lg4 xl4>
                                <v-select hide-details v-model="selectedYear" :items="yearOptions" label="Select Year"
                                    required outlined dense @change="getData"></v-select>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                </v-card-title>
                <v-layout wrap justify-start pa-4 v-if="festivalLeave.length > 0">
                    <v-flex xs12>
                        <v-simple-table class="align-center">
                            <template v-slot:default>
                                <thead style="background-color: #005f32">
                                    <tr>
                                        <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                                            Festival Name
                                        </th>
                                        <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                                            Festival Date
                                        </th>
                                        <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                                            Festival Day
                                        </th>
                                        <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                                            Office Status
                                        </th>
                                        <th style="
                                        font-size: 15px;
                                        color: #ffffff;
                                        font-family: kumbhRegular;
                                      " class="text-left">
                                            Edit
                                        </th>
                                        <th style="
                                        font-size: 15px;
                                        color: #ffffff;
                                        font-family: kumbhRegular;
                                      " class="text-left">
                                            Delete
                                        </th>


                                    </tr>
                                </thead>
                                <tbody class="align-center">
                                    <tr class="text-left align-center" v-for="(item, i) in festivalLeave" :key="i">
                                        <td>
                                            <span v-if="item.festivalName" style="font-family: kumbhMedium">{{
                                                item.festivalName
                                                }}</span>
                                        </td>

                                        <td>
                                            <span v-if="item.festivalDate" style="font-family: kumbhMedium">{{
                                                formatDate(item.festivalDate) }}</span>
                                        </td>

                                        <td>
                                            <span v-if="item.festivalDay" style="font-family: kumbhMedium">{{
                                                item.festivalDay }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item.isOfficeOpen" style="font-family: kumbhMedium">Office
                                                Open</span>
                                            <span v-if="!item.isOfficeOpen" style="font-family: kumbhMedium">Office
                                                Closed</span>
                                        </td>
                                        <td>
                                            <v-layout wrap>
                                                <v-flex xs12 align-self-center text-left>
                                                    <v-icon small color="#005f32" @click="editcat(item)">
                                                        mdi-pencil</v-icon></v-flex>
                                            </v-layout>
                                        </td>
                                        <td>
                                            <v-layout wrap>
                                                <v-flex xs12 align-self-center text-left>
                                                    <v-icon small color="error" @click="openDeleteDialog(item)">
                                                        mdi-delete</v-icon></v-flex>
                                            </v-layout>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-else>
                    <v-flex xs12 align-self-center pa-16>
                        <span class="kumbhRegular">No Data found!</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout wrap justify-center v-if="pages > 0">
            <v-flex align-self-center>
                <div class="pt-2">
                    <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages"
                        :total-visible="7" v-model="currentPage" color="#005f32"></v-pagination>
                </div>
            </v-flex>
        </v-layout>
        <!-- add festival Leaves  -->
        <v-dialog persistent v-model="addFestivalLeave" max-width="600px">
            <v-card>
                <v-layout wrap>
                    <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
                        <v-layout wrap>
                            <v-flex xs6 align-self-center text-left>
                                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                                    Add Festival Leaves</span>
                            </v-flex>
                            <v-flex xs6 align-self-center text-right>
                                <v-btn icon color="white" text @click="addFestivalLeave = false">
                                    <v-icon color="#ffffff">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-4></v-flex>
                    <v-flex xs12>
                        <v-layout wrap px-5 pb-8>
                            <!-- Festival Name Field -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-text-field v-model="festivalName" label="Festival Name" required outlined dense
                                    hide-details></v-text-field>
                            </v-flex>

                            <!-- Festival Date Field (YYYY-MM-DD format) -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="festivalDate" label="Festival Date" readonly outlined
                                            hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker color="#005f32" v-model="festivalDate"
                                        :picker-date.sync="pickerDateString" scrollable
                                        @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-flex>

                            <!-- Festival Day Field -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-select readonly v-model="festivalDay"
                                    :items="['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']"
                                    label="Festival Day" required outlined dense hide-details></v-select>
                            </v-flex>

                            <!-- Is Office Open Field (True/False) -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-switch v-model="isOfficeOpen" label="Is Office Open?" required color="#005f32"
                                    dense></v-switch>
                            </v-flex>

                            <!-- Add Leave Button -->
                            <v-flex xs12 text-end pt-3 pa-2>
                                <v-btn @click="addFestivalLeaves()" color="#005f32">
                                    <span class="kumbhBold" style="color: white">Add Leave</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <!-- Edit festival Leaves  -->
        <v-dialog persistent v-model="editdialog" max-width="600px">
            <v-card>
                <v-layout wrap>
                    <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
                        <v-layout wrap>
                            <v-flex xs6 align-self-center text-left>
                                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">Edit Festival
                                    Leaves</span>
                            </v-flex>
                            <v-flex xs6 align-self-center text-right>
                                <v-btn icon color="white" text @click="editdialog = false">
                                    <v-icon color="#ffffff">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs12 pa-4></v-flex>

                    <v-flex xs12>
                        <v-layout wrap px-5 pb-8>
                            <!-- Festival Name Field -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-text-field v-model="editingitem.festivalName" label="Festival Name" required outlined
                                    dense hide-details></v-text-field>
                            </v-flex>

                            <!-- Festival Date Field (YYYY-MM-DD format) -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editingitem.festivalDate" label="Festival Date" readonly
                                            outlined hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker color="#005f32" v-model="editingitem.festivalDate"
                                        :picker-date.sync="pickerDateString" scrollable
                                        @input="updateFestivalDay"></v-date-picker>
                                </v-menu>
                            </v-flex>

                            <!-- Festival Day Field -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-select readonly v-model="editingitem.festivalDay"
                                    :items="['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']"
                                    label="Festival Day" required outlined dense hide-details></v-select>
                            </v-flex>

                            <!-- Is Office Open Field (True/False) -->
                            <v-flex xs12 sm12 align-self-center text-left pa-2>
                                <v-switch v-model="editingitem.isOfficeOpen" label="Is Office Open?" required
                                    color="#005f32" dense></v-switch>
                            </v-flex>

                            <!-- Add Leave Button -->
                            <v-flex xs12 text-end pt-3 pa-2>
                                <v-btn @click="edit()" color="#005f32">
                                    <span class="kumbhBold" style="color: white">Edit Leave</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
        <!-- delete  Festival Leave -->
        <v-dialog persistent v-model="deletedialog" max-width="600px">
            <v-card>
                <v-layout wrap>
                    <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
                        <v-layout wrap>
                            <v-flex xs6 align-self-center text-left>
                                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                                    Delete Festival Leave
                                </span>
                            </v-flex>
                            <v-flex xs6 align-self-center text-right>
                                <v-btn icon color="white" text @click="deletedialog = false">
                                    <v-icon color="#ffffff">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-4></v-flex>
                    <v-flex xs12>
                        <v-layout wrap px-5 pb-8>
                            <v-flex xs12 align-self-center text-left>
                                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to
                                    delete
                                    this
                                    festival leave?</span>
                            </v-flex>

                            <v-flex xs12 align-self-center text-right pt-3>
                                <v-btn @click="confirmDelete" color="#005f32">
                                    <span class="kumbhBold" style="color: white"> Delete Target </span>
                                </v-btn></v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>




    </div>
</template>
<script>
import axios from "axios";
export default {
    data: (vm) => ({
        showsnackbar: false,
        ServerError: false,
        msg: null,
        pages: 0,
        dialog: false,
        itemToDelete: null,
        deletedialog: false,
        pageCount: 1,
        appLoading: false,
        currentPage: 1,
        g: false,
        searchKeyword: "",
        userId: localStorage.getItem("userid"),
        totalData: 0,
        addcat: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        festivalLeave: [],
        data: [],
        name: null,
        assetId: null,
        dateFilter: new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 7),
        dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
        menu1: false,
        menu2: false,
        dat: "",
        addFestivalLeave: false,
        festivalName: '',
        festivalDate: '',
        festivalDay: '',
        isOfficeOpen: false,
        menu: false,
        pickerDateString: '',
        selectedYear: new Date().getFullYear(),
        editdialog: false,
        editingitem: [],
    }),
    created() {
        this.yearOptions = this.getYearOptions();  // Initialize year options after component is created
    },
    computed: {
        computedDateFormatted() {
            return this.formatDateMonth(this.date);
        },
        formattedFestivalDate() {
            if (this.editingitem.festivalDate) {
                const date = new Date(this.editingitem.festivalDate);
                const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
                const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed
                const year = date.getFullYear();
                return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
            }
            return '';
        },
        // userId() {
        //   return this.$store.state.userData._id;
        // },
    },
    mounted() {
        if (this.$store.state.currentPage) {
            this.currentPage = this.$store.state.currentPage;
            this.getData();
        }
        else this.getData();
    },
    watch: {
        festivalDate(newDate) {
            if (newDate) {
                // Get the day of the week from the festival date
                const date = new Date(newDate);
                const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.

                // Map the number (0-6) to the corresponding day name
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                // Set the festivalDay to the corresponding day
                this.festivalDay = daysOfWeek[dayOfWeek];
            }
        }
    },

    methods: {
        updateFestivalDay() {
            if (this.editingitem.festivalDate) {
                const date = new Date(this.editingitem.festivalDate);
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                this.editingitem.festivalDay = daysOfWeek[date.getDay()]; // Set the day of the week
            }
        },
        editcat(item) {
            // Create a copy of the item to avoid direct mutation
            this.editingitem = { ...item };

            // Format the festivalDate to 'YYYY-MM-DD' before passing it
            if (this.editingitem.festivalDate) {
                const date = new Date(this.editingitem.festivalDate);
                const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
                const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed
                const year = date.getFullYear();
                this.editingitem.festivalDate = `${year}-${month}-${day}`; // Convert to 'YYYY-MM-DD'
            }

            // Set the festivalDay based on the festivalDate
            this.updateFestivalDay();

            // Open the edit dialog
            this.editdialog = true;
        },


        edit() {
            var data = {
                festivalId: this.editingitem._id, // Assuming _id is the identifier of the festival item
                festivalName: this.editingitem.festivalName,
                festivalDate: this.editingitem.festivalDate,
                festivalDay: this.editingitem.festivalDay, // Include the updated festivalDay
                isOfficeOpen: this.editingitem.isOfficeOpen
            };

            axios({
                url: "/admin/festival/update", // Update this URL based on your API
                method: "POST",
                data: data,
                headers: {
                    'x-auth-token': localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.editdialog = false; // Close the dialog
                    this.appLoading = false;

                    if (response.data.status) {
                        this.msg = "Edited Successfully";
                        this.showsnackbar = true;
                        this.getData(); // Reload data
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        addFestivalLeaves() {
            if (!this.festivalName) {
                this.msg = "Please enter festival name.";
                this.showsnackbar = true;
                return;
            }
            if (!this.festivalDate) {
                this.msg = "Please select a festival date.";
                this.showsnackbar = true;
                return;
            }
            if (!this.festivalDay) {
                this.msg = "Please select a festival day.";
                this.showsnackbar = true;
                return;
            }
            if (this.isOfficeOpen === null) {
                this.msg = "Please select whether the office is open or closed.";
                this.showsnackbar = true;
                return;
            }

            this.appLoading = true;
            axios({
                url: "/admin/festival/add",  // Change to the appropriate route
                method: "POST",
                data: {
                    festivalName: this.festivalName,
                    festivalDate: this.festivalDate,
                    festivalDay: this.festivalDay,
                    isOfficeOpen: this.isOfficeOpen,
                },
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                        this.addFestivalLeave = false;

                        // Reset fields
                        this.festivalName = "";
                        this.festivalDate = "";
                        this.festivalDay = "";
                        this.isOfficeOpen = null;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        getYearOptions() {
            const currentYear = new Date().getFullYear();
            const startYear = 2020;
            const years = [];
            for (let year = startYear; year <= currentYear; year++) {
                years.push(year);
            }
            return years;
        },
        openDeleteDialog(item) {
            this.itemToDelete = item;
            this.deletedialog = true;
        },
        confirmDelete() {
            if (this.itemToDelete) {
                this.deleteItem(this.itemToDelete);
            }
            this.deletedialog = false;
        },
        deleteItem(r) {
            var data = {};
            data["festivalId"] = r._id;
            axios({
                url: "/admin/festival/delete",
                method: "post",
                data: data,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showsnackbar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        getData() {
            this.appLoading = true;
            axios({
                url: "/festival/getalllist",
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                params: {
                    page: this.currentPage,
                    limit: 20,
                    year: this.selectedYear,  // Pass the selected year to the API request
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    // const currentYear = new Date().getFullYear();

                    const filteredFestivals = response.data.data.filter(festival => {
                        const festivalYear = new Date(festival.festivalDate).getFullYear();
                        return festivalYear === this.selectedYear; // Filter by selected year
                    });

                    this.festivalLeave = filteredFestivals.sort((a, b) => {
                        return new Date(a.festivalDate) - new Date(b.festivalDate);
                    });

                    this.totalData = filteredFestivals.length;
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

        formatDate(item) {
            var dt = new Date(item);
            var day = String(dt.getDate()).padStart(2, '0'); // Ensure two digits for day
            var month = String(dt.getMonth() + 1).padStart(2, '0'); // Ensure two digits for month
            var year = dt.getFullYear();

            return `${day}-${month}-${year}`; // Return in dd-mm-yyyy format
        },

        formatDateMonth(date) {
            if (!date) return null;
            const [year, month] = date.split("-");
            return `${month}-${year}`;
        },
        formatDateForDisplay(date) {
            if (!date) return '';
            // Assuming the date is in YYYY-MM-DD format
            const [year, month, day] = date.split('-');
            return `${day}-${month}-${year}`;
        },
        formatDateForSaving(date) {
            if (!date) return '';
            const [day, month, year] = date.split('-');
            return `${year}-${month}-${day}`;
        }

    },
};
</script>
<style>
.tableHead {
    font-size: 15px;
    color: #ffffff;
    font-family: kumbhMedium;
}
</style>